import { PermissionContext } from "./PermissionContext";
import {useQuery} from "@apollo/client";
import {CHECK_OPENFGA} from "../Queries";
import {observer} from "mobx-react-lite";
import {useStore} from "./StoreContext";

export const PermissionProvider =observer(({children}:any)=> {
    const store = useStore();
    const {
        loading,
        data
    } = useQuery(CHECK_OPENFGA,{variables:{userId:store.indexStore.userId,permission:"Approve_Registration"}});
    const value = {canApproveRegistration:false}
    if (!loading && data?.check) {
         value.canApproveRegistration = data?.check.allowed;
    }
    else{
        return <div>Loading ...</div>
    }
    return (
        <PermissionContext.Provider value={value}>
            {children}
        </PermissionContext.Provider>);

});