import {makeAutoObservable} from "mobx";

export class RejectionReason {
    constructor() {
        makeAutoObservable(this)

    }
    itemId: string = ""
    openRejectionDialog :boolean = false
    rejectionReason:string="";
}
