import React, {useEffect, useState} from "react";
import {Input, InputLabel, Stack} from "@mui/material";

type input ={
    onChange: (v:any)=>void
    value:string
}

export const  ImageUpload: React.FC<input> = (props:input)=>{


    const [img, setImg] = useState(()=>props.value);

   var  handleUploadClick = (event:any) => {
        var file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = ()=> {
            setImg(reader?.result?.toString() ?? "");
            props.onChange(reader.result);
        }


       setImg(event.target.files[0]);

       props.onChange(reader.result);

    };

    useEffect(() => {
        setImg(props.value);
    }, [props.value]);
    return (
<>
            <Stack alignItems="center">
            <InputLabel  >Signiture:</InputLabel>
            <img   alt="has not been uploaded"   width="400" height="200"
                src={img ?? ""}
            />
                <Input fullWidth={true}  type="file"  onChange={handleUploadClick} placeholder="Signiture"/>

            </Stack>
</>
);
}