
import {action, makeObservable, observable} from "mobx";

export class PsnaToolBar{
    constructor() {
        makeObservable(this,
            {
                userFirstName: observable
                ,setUserFirstName:action
                ,userLastName: observable
                ,setUserLastName:action
            })
    }


    userFirstName :string | null = null;
    setUserFirstName (firstName:string | null ){
        this.userFirstName = firstName;
    }

    userLastName :string | null = null;
    setUserLastName (lastName:string | null ){
        this.userLastName = lastName;
    }
}