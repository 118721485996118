import {action, makeObservable, observable} from "mobx";


export class Index{

    constructor() {
        makeObservable(this,
            {
                signedout: observable
                ,userId: observable
                , registrationId: observable
                , authStatus: observable
                , setSignedout:action
                ,setUserId:action
                ,setAuthStatus:action
                ,setRegistrationId:action

            })
    }
    signedout: boolean = false;
    userId :string|null = null;
    registrationId: string|null = null;
    authStatus :string | null="configuring";
    setSignedout(val:boolean){
        this.signedout = val;
    }
    setUserId(userId:string){
        this.userId = userId;
    }
    setAuthStatus(authStatus:string | null){
            this.authStatus = authStatus;
    }

    setRegistrationId(regId:string){
        this.registrationId = regId;
    }



}