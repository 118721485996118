import {observer} from "mobx-react-lite";

import React from "react";
import {Navigate} from "react-router-dom";
import {usePermissions} from "./providers/PermissionContext";

export const NavigateComponent =observer(() => {
        let ret;
        const permissions  = usePermissions();
        if (permissions.canApproveRegistration) {
            ret =  <Navigate to="administration" replace={true}/>;
        }
        else{
            ret=  <Navigate to="registration" replace={true}/>;
        }
        return ret;
});