import {Registration} from "./Registration";
import { makeAutoObservable} from "mobx";
import {Index} from "./Index";
import {PsnaToolBar} from "./PsnaToolBar";
import {App} from "./App";
import {RejectionReason} from "./RejectionReason";
import {AppPermissions} from "./AppPermissions";
import {RegistrationFilter} from "./RegistrationFilter";
import {Approval} from "./Approval";

export class Store{
    constructor() {
        makeAutoObservable(this)
    }
    rejectionReasonStore: RejectionReason = new RejectionReason();
    approval: Approval = new Approval();
    registrationFilterStore: RegistrationFilter =new RegistrationFilter();
    registrationStore:Registration = new Registration();
    indexStore: Index =new Index();
    psnaToolBarStore : PsnaToolBar=new PsnaToolBar();
    appStore : App = new App();
    permissionsStore:AppPermissions = new AppPermissions();
    reset(){
        this.approval = new Approval();
        this.registrationStore = new Registration();
        this.indexStore = new Index();
        this.psnaToolBarStore = new PsnaToolBar();
        this.appStore = new App();
        this.rejectionReasonStore = new RejectionReason();
        this.permissionsStore = new AppPermissions();
    }
}