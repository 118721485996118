import {makeAutoObservable} from "mobx";

export class Approval {
    constructor() {
        makeAutoObservable(this)

    }
    itemId: string = ""
    openDialog :boolean = false
}
