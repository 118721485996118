import {makeAutoObservable} from "mobx";

export class AppPermissions {
    constructor() {
        makeAutoObservable(this)

    }

    canApproveRegistration :boolean = false
    setcanApproveRegistration(val:boolean){
        this.canApproveRegistration = val;
    }

}
