import {makeAutoObservable} from "mobx";

export class RegistrationFilter {
    constructor() {
        makeAutoObservable((this));
    }
    public province:string = "";
    setProvince(province:string) {
        this.province = province;
    }
    public type:string = "";
    setType(type:string) {
        this.type = type;
    }
    public status:string = "";
    setStatus(status:string) {
        this.status = status;
    }
}