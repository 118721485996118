import {observer} from "mobx-react-lite";
import {AppBar} from "@mui/material";
import {PsnaToolBar} from "./PsnaToolBar";
import {Outlet} from "react-router-dom";

export const Registration = observer(() => {
    return (
        <>
           <AppBar position='sticky'>
           <PsnaToolBar></PsnaToolBar>
           </AppBar>
           <Outlet></Outlet>
        </>
    );
});