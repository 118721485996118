import {gql} from "@apollo/client";

export const GET_USER_REGISTRATION = gql(`
query GetUserRegistration($userId: String!) {
    registrationByUserId(userId:$userId)
    {
      id
      ptype
      status
   }
}
`);

export const CHECK_OPENFGA = gql(`
query check ($userId: string!, $permission: string!){
    check(userId:$userId, permission:$permission) @rest(path:"/openfga/check?{args}", method:"GET"){
    allowed
    }
}
`)

export const SIGNURL = gql(`
fragment Payload on REST {
bucket: String,
key: String
}
query signurl ($input:Payload!){
    signurl(input:$input) @rest(path:"/amazon/signurl", method:"POST"){
    url
    }
}
`)

export const GET_REGISTRATIONS = gql(`
query getRegistrations($province: String!, $type: String!, $status: String!){
    registrations(province:$province, type:$type, status:$status){
     id,
     userId
     luhnRegistrationNumber
     public_officer_firstname
     public_officer_lastname
     email
     practice_Name
     practice_Province
     ptype
     status
    }
}
`)

export const UPDATE_REGISTRATION_STATUS = gql(`
mutation UpdateRegistrationStatus($id:String!, $status:String!, $reason:String){
  updateRegistrationStatus(id: $id, status:$status, reason:$reason){
     id
     luhnRegistrationNumber
     public_officer_firstname
     public_officer_lastname
     email
     practice_Name
     practice_Province
     ptype
     status
  }
}
    `);

export const GET_USER_FULL_REGISTRATION = gql(`
query GetUserRegistration($userId: String!) {
    registrationByUserId(userId:$userId)
    {
      id
      public_officer_firstname
      public_officer_lastname
      ipa_Certified_Number
      mb_Registration_Number
      rn_Expiry
      applicantsTermsInPractice
      postal_Section
      postal_Lot
      postal_Street
      postal_Suburb
      postal_Province
      business_Phone_Number
      mobile_Phone_Number
      email
      location_Creation_Date
      practice_Name
      practice_Section
      practice_Lot
      practice_Street
      practice_Suburb
      practice_Province
      location_Phone_Number
      location_Email
      applicant_Employment_Status
      registered_Business_Name
      ipa_Registration_Number
      business_Type
      premises
      bank
      branch_Number
      branch_Name
      account_Number
      account_Name
      medical_Practitioner_firstname
      medical_Practitioner_lastname
      medical_Practitioner_Signiture
      medical_Certificate
      ipa_Certificate
      tin_Certificate
      created_Date
      updated_Date
      luhnRegistrationNumber
      status
      pbox_Name
      pbox_Branch
      pbox_Province
      pbox_Number
      bucket
   }
}
`);