import {makeAutoObservable, runInAction} from "mobx";
import  {Dayjs} from "dayjs";


export class RegistrationData {
    constructor() {

        makeAutoObservable(this);
    }
    id: string|null = null;
    userId: string|null = null;
    public_officer_firstname: string|null = null;
    public_officer_lastname: string|null = null;
    ipa_Certified_Number: string|null = null;
    mb_Registration_Number: string|null = null;
    rn_Expiry: Dayjs | null = null
    applicantsTermsInPractice: string|null = null;
    postal_Section: string|null = null;
    postal_Lot: string|null = null;
    postal_Street: string|null = null;
    postal_Suburb: string|null = null;
    postal_Province: string|null = null;
    business_Phone_Number: string|null = null;
    mobile_Phone_Number: string|null = null;
    email: string|null = null;
    location_Creation_Date: Dayjs | null = null
    practice_Name: string|null = null;
    practice_Section: string|null = null;
    practice_Lot: string|null = null;
    practice_Street: string|null = null;
    practice_Suburb: string|null = null;
    practice_Province: string|null = null;
    location_Phone_Number: string|null = null;
    location_Email: string|null = null;
    applicant_Employment_Status: string|null = null;
    registered_Business_Name: string|null = null;
    ipa_Registration_Number: string|null = null;
    business_Type: string|null = null;
    premises: string|null = null;
    bank: string|null = null;
    branch_Number: string|null = null;
    branch_Name: string|null = null;
    account_Number: string|null = null;
    account_Name: string|null = null;
    medical_Practitioner_firstname: string|null = null;
    medical_Practitioner_lastname: string|null = null;
    medical_Practitioner_Signiture: string|null = null;
    created_Date: Dayjs | null = null
    updated_Date: Dayjs | null = null
    status: string|null = null;
    ipa_Certificate: string|null = null;
    tin_Certificate: string|null = null;
    medical_Certificate: string|null = null;
    luhnRegistrationNumber: string|null = null;
    ptype: string|null = null;
    bucket: string|null =null;
    pbox_Name: string|null =null;
    pbox_Number:string|null =null;
    pbox_Branch: string|null =null;
    pbox_Province: string|null =null;

    setpType = (val:string)=>{
        runInAction(() => {
            this.ptype = val;
        });
    }
    setBucket = (val:string)=>{
        runInAction(() => {
            this.bucket = val;
        });
    }
    setStatus = (val:string)=>{
        runInAction(() => {
            this.status = val;
        });

    }
    setId(val:string){
        runInAction(() => {
            this.id = val;
        });

    }

}