import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "./providers/StoreContext";

export const RegistrationFilter = observer(() => {
    const store = useStore();
    return (
        <div style={{display:"flex", flexDirection:"row", alignSelf:'center', width:"90%", marginBottom:"30px"}} >


            <FormControl fullWidth>
                <InputLabel id="postal_Province_label">Province</InputLabel>
            <Select sx={{background:"white"}} id="postal_Province" label="Province"

                    labelId="postal_Province_label"
                    onChange={(data)=>store.registrationFilterStore.province = data.target.value}
                    value={store.registrationFilterStore.province}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Bougainville">Bougainville</MenuItem>
                <MenuItem value="Central">Central</MenuItem>
                <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                <MenuItem value="East New Britain">East New Britain</MenuItem>
                <MenuItem value="East Sepik">East Sepik</MenuItem>
                <MenuItem value="Enga">Enga</MenuItem>
                <MenuItem value="Gulf">Gulf</MenuItem>
                <MenuItem value="Madang">Madang</MenuItem>
                <MenuItem value="Manus">Manus</MenuItem>
                <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                <MenuItem value="Morobe">Morobe</MenuItem>
                <MenuItem value="New Ireland">New Ireland</MenuItem>
                <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                <MenuItem value="West New Britain">West New Britain</MenuItem>
                <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                <MenuItem value="National Capital District">National Capital District</MenuItem>
                <MenuItem value="Hela">Hela</MenuItem>
                <MenuItem value="Jiwaka">Jiwaka</MenuItem>
            </Select>
                </FormControl>
            <FormControl fullWidth>
                <InputLabel id="type_label">Type</InputLabel>
                <Select sx={{background:"white"}} id="type" label="Type"

                        labelId="type_label"
                        onChange={(data)=>store.registrationFilterStore.type = data.target.value}
                        value={store.registrationFilterStore.type}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Public">Public</MenuItem>
                    <MenuItem value="Private">Private</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="status_label">Status</InputLabel>
                <Select sx={{background:"white"}} id="type" label="Status"

                        labelId="status_label"
                        onChange={(data)=>store.registrationFilterStore.status = data.target.value}
                        value={store.registrationFilterStore.status}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Acknowledged">Acknowledged</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
            </FormControl>


        </div>
    );
});