import React, {useEffect} from "react";
import SignatureCanvas from "react-signature-canvas";
import {Box, Button, InputLabel, Stack} from "@mui/material";
type Input ={
    onChange: (data:any)=>void
    value:string
}
export const  Signature: React.FC<Input> = (props:Input)=>{

    let sigCanvas: SignatureCanvas| null|any =null;

    useEffect(() => {
        sigCanvas.fromDataURL(props.value,{ratio:1,width:400, height:200})
    }, [sigCanvas,props]);
    let onEnd =()=>{
        props.onChange(sigCanvas.toDataURL());
    }
    return(
<Stack direction="column"
       justifyContent="space-evenly"
       alignItems="center">
    <InputLabel  >Signature:</InputLabel>
    <Box component="div">
    <SignatureCanvas canvasProps={{width:400,height:200}}  onEnd={onEnd} ref={(ref) => {sigCanvas = ref }} />
    </Box>
    <Button  onClick={()=>sigCanvas.clear()}>Clear</Button>
</Stack>
    )

}