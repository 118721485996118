import {
    Box, Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography,
} from "@mui/material";
import {useMutation, useQuery} from "@apollo/client";
import {observer} from "mobx-react-lite";
import {GET_REGISTRATIONS,UPDATE_REGISTRATION_STATUS} from "./Queries";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import {green} from "@mui/material/colors";
import {red} from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';
import {DownloadDone} from "@mui/icons-material";
import {useStore} from "./providers/StoreContext";
export const RegistrationTable = observer(() => {
    const store = useStore();
    const Item = (row: any) => {
        const target = row.row;
        if (target.status === "Approved")
            return <DoneIcon sx={{color: green[500]}} />
        if (target.status === "Rejected")
            return <CloseIcon sx={{color: red[500]}} />
        if (target.status === "Acknowledged")
            return <DownloadDone sx={{color: green[500]}}/>

        return <Box  sx={{cursor: "pointer"}}> <CheckCircleIcon color="primary" onClick={()=>{store.approval.itemId = target.id; store.approval.openDialog=true}}/><CancelIcon
            color="secondary" onClick={()=>{store.rejectionReasonStore.itemId = target.id; store.rejectionReasonStore.openRejectionDialog=true}} /></Box>
    }
    const UpdateRejection=()=>{
        store.rejectionReasonStore.openRejectionDialog=false;
        updateStatus({variables: {"id": store.rejectionReasonStore.itemId, "status": "Rejected", "reason":store.rejectionReasonStore.rejectionReason}}).then();
    }
    const UpdateApprove=()=>{
        store.approval.openDialog=false;
        updateStatus({variables: {"id": store.approval.itemId, "status": "Approved", "reason":null}}).then();
    }
    const {loading, error, data} = useQuery(GET_REGISTRATIONS,
        {variables:
                {
                    province:store.registrationFilterStore.province,
                    type:store.registrationFilterStore.type,
                    status:store.registrationFilterStore.status,
                }});
    const [updateStatus] = useMutation(UPDATE_REGISTRATION_STATUS,{awaitRefetchQueries:true, refetchQueries: [GET_REGISTRATIONS, 'getRegistrations']});

    if (loading)
        return <div style={{alignSelf:'center'}}>Loading...</div>;
    if (error) {
        return  <div style={{alignSelf:'center'}}>{error.message}</div>;
    }
    if (data.registrations.length > 0) {
        return <div>
        <TableContainer   component={Paper} >
            <Table key="DataTable" >
                <TableHead>
                    <TableRow>
                        <TableCell>Registration Id</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Practice Name</TableCell>
                        <TableCell>Province</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.registrations.map((row: any, idx:number) => (
                        <TableRow key={idx}>
                            <TableCell><a href={`/administration/${row.userId}`}>{row.luhnRegistrationNumber}</a></TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.public_officer_firstname}</TableCell>
                            <TableCell>{row.public_officer_lastname}</TableCell>
                            <TableCell>{row.practice_Name}</TableCell>
                            <TableCell>{row.practice_Province}</TableCell>
                            <TableCell>{row.ptype}</TableCell>
                            <TableCell><Item row={row}></Item></TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open = {store.rejectionReasonStore.openRejectionDialog}  >
            <DialogTitle>Reason for Rejection</DialogTitle>
            <DialogContent>
                <textarea rows={10} cols={50} onChange={event => store.rejectionReasonStore.rejectionReason = event.target.value}></textarea>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>store.rejectionReasonStore.openRejectionDialog=false}>Cancel</Button>
                <Button onClick={UpdateRejection}>Update</Button>
            </DialogActions>
        </Dialog>
        <Dialog open = {store.approval.openDialog}  >
            <DialogTitle>Approval</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to approve this applicant?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>store.approval.openDialog=false}>No</Button>
                <Button onClick={UpdateApprove}>Yes</Button>
            </DialogActions>
        </Dialog>
        </div>
    } else {
        return <div style={{alignSelf:'center'}}>There is not any Registration meeting filter criteria</div>;
    }
});