import {Stack} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Link } from "react-router-dom";

export const TypePicker = observer(() => {


    return (
        <Stack>
            <div style={{marginTop: 30}}>
                All Non-government Health Care Providers:
            </div>
            <Link to={'private'} state={{val: "private"}}>Private Service Provider Registration</Link>
            <div style={{marginTop: 30}}>
                All Government Hospitals, Clinics and Medical Centers:
            </div>
            <Link to={'public'} state={{val: "public"}}>Provincial Health Authority Registration</Link>
        </Stack>
    );
});