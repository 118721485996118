import {makeAutoObservable} from "mobx";

export class App{

    constructor() {
        makeAutoObservable(this);
    }
    openSnackBar: boolean = false;
    snackBarMessage:string = "";
    token :string|null  = "";
    identityId:string|undefined = undefined;

    setIdentityId(identityId:string|undefined):void {
        this.identityId = identityId;
    }

    setToken(link:string|null){
        if (link!==undefined){
            this.token  = link;
        }
    }
    setOpenSnackbar(val:boolean){
        this.openSnackBar  = val;
    }
    showSnackBar(message:string){
        this.snackBarMessage = message;
        this.openSnackBar = true;
    }
}