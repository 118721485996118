
import {observer} from "mobx-react-lite";
import {StoreContext} from "./StoreContext";
import {Store} from "../store/Store";

export const StoreProvider =observer(({children}:any)=> {
    const value = new Store();
    return (
        <StoreContext.Provider value={value}>
            {children}
        </StoreContext.Provider>);
});